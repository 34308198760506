<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabmenu_wrap">
        <ul class="tabmenu">
          <li class="tabl" style="width:33%" @click="$router.push({ path: '/policy/useTerms' })"><span>이용약관</span></li>
          <li class="tabl on" style="width:34%">
            <span>요금신고<br />내역</span>
          </li>
          <li class="tabl" style="width:33%" @click="$router.push({ path: '/policy/spamPolicy' })"><span>스팸정책</span></li>
        </ul>
      </div>
      <div class="tabcont_wrap policy_wrap">
        <div class="policy chargeNotice">
          <div class="policy_top">
            <h3 class="policy_title">요금신고내역</h3>
            <p class="policy_sub">과학기술정보통신부에 신고된 메시징 이용요금을 안내드립니다.</p>
            <p class="location">HOME - 요금신고내역</p>
          </div>
          <p class="subtit" style="line-height: 40px">
            ■ 기업메시징 과학기술정보통신부 요금 신고 안내<br />
            1. 전기통신사업법 제 22조 4항에 근거 LG U+가 제공가능한 이용 요금 구간을 안내드립니다<br />
            2. xMS 이용 요금표
          </p>

          <!-- SMS 요금표 시작 -->
          <div class="bbsLstBasic">
            <table summary="계약 구간 , 계약(이용) 요금 에 대한 정보를 확인 할 수 있습니다.">
              <caption>
                이용요금표 리스트
              </caption>
              <colgroup>
                <col width="10%" />
                <col width="17%" />
                <col width="17%" />
                <col colspan="8" width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">구분</th>
                  <th scope="col" colspan="2">계약 구간(단위 : 건)</th>
                  <th scope="col" colspan="8">계약(이용)요금(단위:원,부가세포함)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" rowspan="4">SMS(90Byte)</th>
                  <th scope="col">월 이용</th>
                  <th scope="col">년 이용</th>
                  <th scope="col" colspan="4">최고</th>
                  <th scope="col" colspan="4">최저</th>
                </tr>
                <tr>
                  <td>0 ~ 9,999</td>
                  <td>0 ~ 99,999</td>
                  <td colspan="4">27.5</td>
                  <td colspan="4">8.8</td>
                </tr>
                <tr>
                  <td>10,000 ~<br />99,999</td>
                  <td>100,000 ~<br />999,999</td>
                  <td colspan="4">16.5</td>
                  <td colspan="4">8.3</td>
                </tr>
                <tr>
                  <td>100,000 ~</td>
                  <td>1,000,000 ~</td>
                  <td colspan="4">13.2</td>
                  <td colspan="4">7.9</td>
                </tr>
                <tr>
                  <th scope="row" rowspan="5">LMS(2,000Byte)</th>
                  <th scope="col" rowspan="2">월 이용</th>
                  <th scope="col" rowspan="2">년 이용</th>
                  <th scope="col" colspan="4">최고</th>
                  <th scope="col" colspan="4">최저</th>
                </tr>
                <tr>
                  <th scope="col">SKT</th>
                  <th scope="col">KT</th>
                  <th scope="col">LGU+</th>
                  <th scope="col">통합</th>
                  <th scope="col">SKT</th>
                  <th scope="col">KT</th>
                  <th scope="col">LGU+</th>
                  <th scope="col">통합</th>
                </tr>
                <tr>
                  <td>0 ~ 9,999</td>
                  <td>0 ~ 99,999</td>
                  <td>55.0</td>
                  <td>55.0</td>
                  <td>55.0</td>
                  <td>55.0</td>
                  <td>26.4</td>
                  <td>26.4</td>
                  <td>30.8</td>
                  <td>27.3</td>
                </tr>
                <tr>
                  <td>10,000 ~<br />99,999</td>
                  <td>100,000 ~<br />999,999</td>
                  <td>36.3</td>
                  <td>36.3</td>
                  <td>36.3</td>
                  <td>36.3</td>
                  <td>25.9</td>
                  <td>25.9</td>
                  <td>28.0</td>
                  <td>26.4</td>
                </tr>
                <tr>
                  <td>100,000 ~</td>
                  <td>1,000,000 ~</td>
                  <td>30.8</td>
                  <td>30.8</td>
                  <td>36.3</td>
                  <td>31.9</td>
                  <td>25.5</td>
                  <td>25.5</td>
                  <td>27.7</td>
                  <td>26.0</td>
                </tr>
                <tr>
                  <th scope="row" rowspan="4">MMS(2,000Byte + 1M)</th>
                  <th scope="col">월 이용</th>
                  <th scope="col">년 이용</th>
                  <th scope="col" colspan="4">최고</th>
                  <th scope="col" colspan="4">최저</th>
                </tr>
                <tr>
                  <td>0 ~ 9,999</td>
                  <td>0 ~ 99,999</td>
                  <td colspan="4">220</td>
                  <td colspan="4">55.0</td>
                </tr>
                <tr>
                  <td>10,000 ~<br />24,999</td>
                  <td>100,000 ~<br />249,999</td>
                  <td colspan="4">110</td>
                  <td colspan="4">50.6</td>
                </tr>
                <tr>
                  <td>25,000 ~</td>
                  <td>250,000 ~</td>
                  <td colspan="4">77</td>
                  <td colspan="4">49.7</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- SMS 요금표 끝 -->
          <br />
          <p class="subtit" style="line-height: 40px">3. RCS 이용 요금표</p>
          <!-- RCS 요금표 시작 -->
          <div class="bbsLstBasic">
            <table summary="">
              <caption>
                이용요금표 테이블
              </caption>
              <colgroup>
                <col width="10%" />
                <col width="19%" />
                <col width="19%" />
                <col width="26%" />
                <col width="26%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">구분</th>
                  <th scope="col" colspan="2">계약 구간(단위 : 건)</th>
                  <th scope="col" colspan="2">계약(이용) 요금(단위 : 원, 부가세 포함)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" rowspan="4">RCS <br />SMS</th>
                  <th scope="col">월 이용</th>
                  <th scope="col">년 이용</th>
                  <th scope="col">최고</th>
                  <th scope="col">최저</th>
                </tr>
                <tr>
                  <td>0 ~ 9,999</td>
                  <td>0 ~ 99,999</td>
                  <td>27.5</td>
                  <td>8.8</td>
                </tr>
                <tr>
                  <td>10,000 ~ <br />99,999</td>
                  <td>100,000 ~ <br />999,999</td>
                  <td>16.5</td>
                  <td>8.3</td>
                </tr>
                <tr>
                  <td>100,000 ~</td>
                  <td>1,000,000 ~</td>
                  <td>13.2</td>
                  <td>7.9</td>
                </tr>

                <tr>
                  <th scope="row" rowspan="4">RCS <br />LMS</th>
                  <th scope="col">월이용</th>
                  <th scope="col">년 이용</th>
                  <th scope="col">최고</th>
                  <th scope="col">최저</th>
                </tr>
                <tr>
                  <td>0 ~ 9,999</td>
                  <td>0 ~ 99,999</td>
                  <td>55.0</td>
                  <td>26.4</td>
                </tr>
                <tr>
                  <td>10,000 ~ <br />99,999</td>
                  <td>100,000 ~ <br />999,999</td>
                  <td>36.3</td>
                  <td>25.9</td>
                </tr>
                <tr>
                  <td>100,000 ~</td>
                  <td>1,000,000 ~</td>
                  <td>30.8</td>
                  <td>25.5</td>
                </tr>
                <tr>
                  <th scope="row" rowspan="4">RCS <br />MMS</th>
                  <th scope="col">월 이용</th>
                  <th scope="col">년 이용</th>
                  <th scope="col">최고</th>
                  <th scope="col">최저</th>
                </tr>
                <tr>
                  <td>0 ~ 9,999</td>
                  <td>0 ~ 99,999</td>
                  <td>220</td>
                  <td>55.0</td>
                </tr>
                <tr>
                  <td>10,000 ~ <br />24,999</td>
                  <td>100,000 ~ <br />249,999</td>
                  <td>110</td>
                  <td>50.6</td>
                </tr>
                <tr>
                  <td>25,000 ~</td>
                  <td>250,000 ~</td>
                  <td>77</td>
                  <td>49.7</td>
                </tr>
                <tr>
                  <th scope="row" rowspan="4">RCS <br />템플릿</th>
                  <th scope="col">월 이용</th>
                  <th scope="col">년 이용</th>
                  <th scope="col">최고</th>
                  <th scope="col">최저</th>
                </tr>
                <tr>
                  <td>0 ~ 9,999</td>
                  <td>0 ~ 99,999</td>
                  <td>25.3</td>
                  <td>6.6</td>
                </tr>
                <tr>
                  <td>10,000 ~ <br />99,999</td>
                  <td>100,000 ~ <br />999,999</td>
                  <td>14.3</td>
                  <td>6.1</td>
                </tr>
                <tr>
                  <td>100,000 ~</td>
                  <td>1,000,000 ~</td>
                  <td>11.0</td>
                  <td>5.7</td>
                </tr>
                <tr>
                  <th scope="row" rowspan="4">RCS 이미지 템플릿</th>
                  <th scope="col">월 이용</th>
                  <th scope="col">년 이용</th>
                  <th scope="col">최고</th>
                  <th scope="col">최저</th>
                </tr>
                <tr>
                  <td>0 ~ 9,999</td>
                  <td>0 ~ 99,999</td>
                  <td>160</td>
                  <td>40.1</td>
                </tr>
                <tr>
                  <td>10,000 ~ 99,999</td>
                  <td>100,000 ~ 999,999</td>
                  <td>100</td>
                  <td>36.5</td>
                </tr>
                <tr>
                  <td>100,000 ~</td>
                  <td>1,000,000 ~</td>
                  <td>60</td>
                  <td>32.9</td>
                </tr>
              </tbody>
            </table>
            <br />
            ※ RCS 이미지 템플릿의 메시지 유형 및 용량은 1MB+500자 범위 내에서 메시지 양식에 따라 다르게 제공됩니다. 단, 금융소비자 보호에 관한 법률 및 금융광고규제 가이드라인과 같이 법규에 의하여 의무적으로 메시지에 포함하여 전송하여야 하는 내용을 위한 용량은 상기 용량(500자)와 별도로 제공될 수 있습니다.
          </div>
          <!-- RCS 요금표 끝 -->
          <br />
          <!-- RCS양방향 이용 요금 시작 -->
          <p class="subtit" style="line-height: 40px">4. RCS양방향 이용 요금</p>
          <p class="right">(부가가치세 포함)</p>
          <div class="bbsLstBasic">
            <table summary="">
              <caption>
                이용요금표 테이블
              </caption>
              <colgroup>
                <col width="20%" />
                <col width="30%" />
                <col width="50%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">구분</th>
                  <th scope="col">내용</th>
                  <th scope="col">비고</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td >세션당 이용요금</td>
                  <td>
                    <ul>
                      <li>5.7원/건,</li>
                      <li>최대 57원/세션</li>
                    </ul>
                  </td>
                  <td class="left">‘최대 57원/세션’의 의미: “이용자”가 전송한 “RCS양방향” 메시지가 한 세션 내에서 10건 이상일 경우, 57원을 과금함</td>
                </tr>
                <tr>
                  <td>세션기준</td>
                  <td >
                    <ul>
                      <li>“고객”의 이동통신 회선번호에서 전송한 기업의 전화번호당 24시간 단위</li>
                      <li>(24시간의 기준시: 04시)</li>
                    </ul>
                  </td>
                  <td class="left">
                    <ul>
                    <li>(예시) “고객”의 이동통신 회선번호 010-1111-2222에서 기업의 전화번호 1544-0001로 (N)일 04:10분에 문의를 전송한 후 (N+1)일 03:50분까지 12건 응답메시지를 수신하고, 04:00 ~ 05:10분까지 6건 응답메시지를 수신했을 경우 요금:</li>
                    <li>(N)일의 세션 요금: 5.7원 x 12건 = 68.4원</li>
                    <li>세션 최대요금 57원 적용</li>
                    <li>(N+1)일의 세션 요금: 5.7 x 6건 = 34.2원 </li>
                    <li>요금 합계: 57원+34.2원 = 91.2원 부과</li>
                  </ul>
                  ※ 고객의 이동통신 회선번호는 동일하고 전송한 기업의 전화번호가 다른 경우 별도의 세션으로 과금함</td>
                </tr>
                <tr>
                  <td>사용조건</td>
                  <td>기업이 “고객”의 문의/요청을 받아 이에 대한 응답 메시지를 전송하는 경우 </td>
                  <td class="left">
                    <ul>
                      <li>(예시)</li>
                      <li>”고객”이 기업의 전화번호로 문자를 전송하여 이에 응답한 경우</li>
                      <li>“고객”이 문자함의 대화방 메뉴를 선택 시 응답한 경우</li>
                      <li>“고객”이 기업의 대화방을 검색하여 최초 진입 시 응답한 경우</li>
                      <li>○ 대화방 : "고객"이 단말기 문자함에서 메시지를 수신했을 때 기업의 발신자 번호 기준으로 묶이는 단위</li>
                      <li>○ 대화방 메뉴 : 기업이 “고객”에게 알리고자 하는 내용을 항목화하여 대화방에 고정적으로 표시하는 메뉴</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- RCS 탬플릿 요금표 끝 -->
          <div>
            <br />
            [“RCS 양방향” 메시지 유형 및 용량]
            <br />
            <br />
            <ul>
              <li>※ “RCS 양방향” 메시지 유형 및 용량은 RBC(RCS Biz Center)를 통해서 공지하며, 사전 공지를 통해 "회사" 정책에 따라 변경될 수 있음</li>
              <li>※ RCS양방향 메시지는 고객의 실시간 문의/요청(예: 고객이 대화방에서 문의메시지를 전송하거나, 대화방 메뉴를 선택 등)에 대해서 응답하는 모든 메시지(챗봇응답 및 대화방메뉴 자동응답 메시지 등)에 대해 과금한다.</li>
            </ul>
          </div>
          <div>
            <br />
            (이용조건)
            <br />
            <br />
            <ul>
              <li>1) LGU+ 기업메시징서비스는 기업대상 서비스로 법인 또는 개인사업자 대상 서비스임. 단, 중앙선거관리위원회 관리를 받는 개인은 선불이용이 가능함</li>
              <li>2) 기업의 특성(연 단위 예산확보 및 집행)을 고려하여 월/년 단위 발송기준으로 계약을 체결하며, 건당 발송 성공건수와 계약 단가를 곱하여 요금을 청구함</li>
              <li>3) 구간 내 슬라이딩 단가를 기준으로 계약을 체결할 수 있으며, 이용건수에 따라 요금을 청구함</li>
              <li>4) 이용고객에게 설치료 및 부대 서버관리비용을 면제해주는 대신, 월 최저이용료를 부과할 수 있음</li>
              <li>5) LGU+는 추가할인정책과 프로모션을 시행할 수 있으며, 이용고객은 당사 서비스 홈페이지에 게시되는 할인 정책과 프로모션을 참조하여 이용할 수 있음</li>
            </ul>
          </div>
          <br />
          <p class="subtit" style="line-height: 40px">5. 시행일: 2024.05.17 (신규 또는 갱신 시 적용)</p>
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
</template>
<script>
import MobileGnb from "@/components/MobileGnb.vue";
export default {
  name: "chargeNotice",
  components: { MobileGnb },
  data() {
    return {
      example: "",
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {},
};
</script>
