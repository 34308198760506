<template>
  <!-- foot_type_user -->
  <footer class="footer_wrap">
    <div class="footer">
      <div class="f_t">
        <ul class="fm_wrap">
          <li class="fml01"><router-link to="/policy/useTerms" class="fma01">이용약관</router-link></li>
          <li class="fml01"><router-link to="/policy/chargeNotice" class="fma01">요금신고내역</router-link></li>
          <li class="fml01">
            <a class="fma01" @click="idvInfoPopup"><span class="b" style="color: #e6007e;">개인정보처리방침</span></a>
          </li>
          <li class="fml01"><router-link to="/policy/spamPolicy" class="fma01">스팸정책</router-link></li>
        </ul>
        <div class="family_wrap">
          <select name="" id="" @change="bizReady">
            <option value="" disabled selected hidden>Family Site</option>
            <option value="https://bizmarket.uplus.co.kr/">비즈마켓</option>
            <option value="https://www.lguplus.com/biz">LG U+ (기업)</option>
            <option value="https://webfax.uplus.co.kr">U+웹팩스</option>
            <option value="https://edocu.uplus.co.kr">U+전자문서</option>
            <option value="https://support.uplus.co.kr">U+원격지원</option>
            <option value="https://only.webhard.co.kr">웹하드</option>
          </select>
        </div>
      </div>
      <div class="f_b">
        <div class="flogo"><a href="https://www.lguplus.com/"></a></div>
        <div class="finfo">
          <ul>
            <li>주소:서울특별시 용산구 한강대로 32</li>
            <li>대표이사: 홍범식</li>
            <li>사업자등록번호:220-81-39938</li>
            <li>상호명: (주) 엘지유플러스</li>
            <li>통신판매신고:제2015-서울용산-00481호</li>
          </ul>
          <p>고객센터 1544-5992(평일 09시~18시, 공휴일/주말 휴무)</p>
          <p>해당 웹사이트는 크롬(Chrome), 엣지(Edge), 사파리(Safari)에 최적화되어 있습니다.</p>
        </div>
        <p class="copy">Copyright ⓒ LG Uplus Corp. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: "mainFooter",
  components: {},
  data() {
    return {
      example: "",
    };
  },
  methods: {
    bizReady(e) {
      window.open(e.target.value, "_blank");
    },
    idvInfoPopup(){
      window.open('https://privacy.lguplus.com/2545ce4416cc4e3b9d9488b94690ba27/privacy/info/v1/1','idvInfoPopup', "top=0, left=50, width=950px, height=750px, resizable=no,status=no")
    }
  },
};
</script>
